.Video {
    width: 100%;
    height: 500px;
    position: relative;
    overflow: hidden;

    &:before {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #00000075;
        content: '';
    }

    video {
        min-width: 100%;
        min-height: 100%;
    }

    &Mobile {
        max-width: 140%;
    }
}

.Content {
    top: 0;
    width: 70%;
    display: flex;
    flex-direction: column;
    margin-bottom: -400px;
    height: 400px;
    justify-content: center;
    grid-gap: 20px;
    gap: 20px;
    align-items: flex-start;
    overflow: hidden;
    position: relative;
    z-index: 1;

    h1 {
        font-weight: 600;
        font-size: 36px;
        color: #fff;
        margin: 0;
        //background-color: #2647ffc7;
        //padding: 5px 10px;
        border-radius: 5px;
    }
}

.Buttons {
    display: flex;
    gap: 20px;
    margin-top: 20px;
}

.Full {
    font-size: 15px;
    font-weight: 500;
    position: relative;
    color: #fff;
    padding-left: 70px;
    cursor: pointer;

    &:before {
        content: '';
        background-image: url("/video.png");
        width: 52px;
        height: 52px;
        position: absolute;
        top: -17px;
        left: 0;
    }
}

@media only screen and (max-width: 600px) {
    .Content {
        width: 100%;
    }
}